import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { RichText, RichTextReact } from "wmk-rich-text";
import { colors } from "../../../vars/palette";
import Theme from "../../../vars/ThemeOptions";
import { options } from "../../blocks/RichText/richTextOptions";

const BlogBodyWrap = styled(Col)`
  padding: 0 3rem;
  max-width: 780px !important;
  width: 100;
  margin: 2rem auto !important;
  & > div:first-child {
    font-size: 175%;
    line-height: 1.3;
    color: ${colors.hex("secondary")};
    text-transform: uppercase;
    font-family: ${Theme.font.head};
    a {
      color: ${colors.hex("tertiary")};
      border: none;
      &:hover {
        color: ${colors.hex("primary")};
        u {
          text-decoration: none;
        }
      }
    }
  }
  div:not(:first-child) {
    margin: 2rem 0;
    line-height: 1.75;
    font-size: 1.425rem;
  }
  h2,
  h3,
  h6,
  blockquote {
    margin: 2rem 0;
    color: ${colors.hex("secondary")};
    font-family: ${Theme.font.head};
    text-transform: uppercase;
  }
  h5,
  h6,
  blockquote {
    font-weight: bold;
    text-transform: uppercase;
  }
  h6,
  blockquote {
    font-size: 1.5rem;
  }
  b,
  strong {
    font-weight: 600;
  }
  a:not(.cta-ttb-button) {
    color: ${colors.hover("tertiary", 0.1, "flip")};
    transition: all 0.3s ease;
    border-bottom: 1px solid ${colors.rgba("primary", 1)};
    text-decoration: none;
    &.cta_button {
      border: none;
    }
    &:hover {
      text-decoration: none;
      transition: all 0.3s ease;
      color: ${colors.hex("primary")};
      border: 0px solid ${colors.rgba("secondary", 0)};
    }
    u {
      text-decoration: none;
    }
  }
  .embedded-image-wrap {
    text-align: center;
    padding: 1rem 0 2rem 0;
  }
  .embedded-youtube-wrap {
    text-align: center;
    padding: 1rem 0 2rem 0;
  }
  img {
    max-width: 100%;
  }
  ol,
  ul {
    padding: 0 0 0 1.5rem;
    line-height: 1.75;
    //color: ${colors.hex("black")};
    font-size: 1.425rem;
  }
  .hubspot-cta {
    display: flex;
    justify-content: center;
    text-decoration: none;
    // hover not working because these are rendered as images
    // a:hover {
    //   border-bottom: 0;
    //   background: white;
    //   color: ${colors.hex("secondary")};
    //   border: 2px solid ${colors.hex("secondary")};
    // }
  }
  .hs-cta-wrapper {
    position: relative;
    top: -1.5rem;
  }
  .react-player {
    height: 425px !important;
    width: calc(
      100% + calc(3rem + calc(var(--bs-gutter-x) * 0.5))
    ) !important;
    position: relative;
    left: calc(-3rem + calc((var(--bs-gutter-x) * 0.5) * 1.5));
    @media screen and (max-width: 768px) {
      height: 270px !important;
    }
    @media screen and (max-width: 576px) {
      height: 230px !important;
    }
  }
`;

const BlogBody = ({ content }: { content: RichText }) => {
  return (
    <Container>
      <Row>
        <BlogBodyWrap>
          <RichTextReact content={content} options={options} />
        </BlogBodyWrap>
      </Row>
    </Container>
  );
};

export default BlogBody;

import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";
import BackSlash from "../../common/TitleSlash";
import Hero from "../../common/ParallaxHero";
import BlogDate from "./BlogDate";
import BlogTags from "./Tags/BlogTags";
import RelatedBlogs from "./RelatedBlogs";
import { colors } from "../../../vars/palette";
import { WmkImage } from "wmk-image";
import { Blog, Blogs } from "../../../classes/Blog";
import BlogBody from "./BlogBody";
import { navigate } from "gatsby";
import { WmkLink } from "wmk-link";
import GibsonButton from "../../common/GibsonButton";
import { useState, useEffect } from "react";

const BlogWrap = styled.div`
  .title {
    text-transform: uppercase;
  }
  .blog-header {
    position: relative;
    z-index: 101; // makes sure the blog title box is layered on top of the parallax hero image
    padding: 2rem;
    background: ${colors.hover("text", 0.5, true)};
    color: ${colors.hex("reverse")};
    .title {
      font-family: ${Theme.font.head};
      line-height: 1.2;
      font-weight: 700;
    }
    margin-top: -8rem;
  }
  .topics,
  .author {
    font-size: 0.9rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    & > .row {
      align-items: center;
    }
    .col-auto {
      padding: 0 0 0 2rem;
    }
  }
  .topics {
    text-align: right;
  }
  .date {
    font-size: 1rem;
    font-weight: bold;
    color: ${colors.rgba("white", 0.5)};
  }
  .by {
    text-transform: none;
  }
  .back-link {
    color: ${colors.hex("primary")};
    position: absolute;
    z-index: 1000;
    margin-left: 0.7rem;
    margin-top: 0.2rem;
    p {
      padding-right: 0.2rem;
      font-weight: 700;
      font-size: 22px;
      background: ${colors.rgba("white", 0.8)};
    }
  }
  .author-img-col {
    img {
      height: 60px;
      width: 60px;
      object-position: top;
    }
  }
  .buttonPositionContainer {
    position: relative;
  }
  .buttonSubDiv {
    width: fit-content;
    position: absolute;
    top: 2.4rem;
    left: -0.75rem;

    a {
      padding: 0.5rem 2rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    .buttonPositionContainer {
      position: relative;
    }
    .buttonSubDiv {
      width: fit-content;
      position: relative;
      top: 0;
      left: 0;
      margin: 2rem 78px !important;
    }
    .buttonMobileCol {
      padding-left: 3rem;
    }
  }
  @media only screen and (max-width: 991px) {
    .buttonSubDiv {
      margin: 2rem 0 !important;
    }
  }
`;

type blogProps = {
  blog: Blog;
  all: Blogs;
};

const BlogSingle = ({ blog, all }: blogProps) => {
  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleResize = () => {
    const winWidth = window.innerWidth;
    setViewWidth(winWidth);
  };
  useEffect(() => {
    console.log("viewWidth", viewWidth);
  }, [viewWidth]);
  const related = all.list.slice(0, 4);
  return (
    <BlogWrap>
      {/* <WmkLink to={`/blog#${blog.slug}`} className="back-link">
        <p>{`< BACK`}</p>
      </WmkLink> */}
      <Hero image={blog.image} strength={100} />
      <Container>
        <Row className="flex-column blog-header">
          <Col>
            <BackSlash />
          </Col>
          <Col className="date">
            <BlogDate timestamp={blog.timestamp} />
          </Col>
          <Col>
            <h1 className="title">{blog.title}</h1>
          </Col>
          <Row>
            <Col className="author">
              <Row>
                <Col xs="auto" className="author-img-col">
                  <WmkImage image={blog?.author?.image} />
                </Col>

                {blog?.author && blog?.author?.name && (
                  <Col>
                    <span className="by">by</span> {blog?.author?.name}
                  </Col>
                )}
                <Col>
                  <BlogTags tags={blog.tags} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
        {viewWidth >= 1200 ? (
          <div className="buttonPositionContainer">
            <div className="buttonSubDiv">
              <GibsonButton to={`/blog#${blog.slug}`} direction="left">
                <p>{`< BACK`}</p>
              </GibsonButton>
            </div>
          </div>
        ) : (
          <Container>
            <Row>
              <Col className="buttonMobileCol">
                <div className="buttonSubDiv">
                  <GibsonButton to={`/blog#${blog.slug}`} direction="left">
                    <p>{`< BACK`}</p>
                  </GibsonButton>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <BlogBody content={blog.content} />
        <RelatedBlogs related={related} />
      </Container>
    </BlogWrap>
  );
};

export default BlogSingle;

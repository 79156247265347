import * as React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import BlogPost from "../components/routes/Blog/BlogSingle";
import { WmkSeo } from "wmk-seo";
import { Blog, Blogs } from "../classes/Blog";
import { BlogFields } from "../fragments/nodeBlogFields";
import { SiteMetadataFields } from "../fragments/nodeSIteMetadataFields";

interface BlogNodeQuery {
  data: {
    all: {
      edges: { node: BlogFields }[];
    };
    meta: SiteMetadataFields;
    blog: BlogFields;
  };
}

const BlogNode = ({ data }: BlogNodeQuery) => {
  const meta = data.meta;
  const all = new Blogs(data.all.edges);
  const blog = new Blog(data.blog);
  const slug = blog.slug;
  const description = blog.content.excerpt(120);
  return (
    <Layout>
      <WmkSeo.Meta
        title={blog.title}
        path="/blog/"
        slug={slug}
        description={description ? description : blog.title}
        ogImageUrl={blog.image.url("ogImage")}
        twitterImageUrl={blog.image.url("twitterImage")}
        twitterHandle={meta?.siteMetadata?.author}
        siteTitle={meta?.siteMetadata?.title}
        baseUrl={meta?.siteMetadata?.baseUrl}
      />
      <WmkSeo.Schema
        graphs={[
          {
            "@type": "BlogPosting",
            "@id": meta?.siteMetadata?.baseUrl + "/#blog-" + blog.slug,
            headline: blog.title,
            keywords: blog.tags.join(),
            datePublished: blog.timestamp,
            dateModified: blog.updated,
          },
        ]}
        siteTitle={meta?.siteMetadata?.title}
        baseUrl={meta?.siteMetadata?.baseUrl}
      />
      <BlogPost blog={blog} all={all} />
    </Layout>
  );
};

export default BlogNode;

export const query = graphql`
  query blogQuery($slug: String!, $tags: [String]) {
    all: allContentfulWmkBlogs(
      filter: { tags: { in: $tags }, slug: { ne: $slug } }
    ) {
      edges {
        node {
          ...NodeBlogExcerptFields
        }
      }
    }
    meta: site {
      ...NodeSiteMetadataFields
    }
    blog: contentfulWmkBlogs(slug: { eq: $slug }) {
      ...NodeBlogFields
    }
  }
`;

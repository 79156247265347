import * as React from "react";
import styled from "styled-components";
import { colors } from "../../vars/palette";

const SlashWrap = styled.div`
  span {
    color: ${colors.hex("primary")};
    font-weight: 100;
    padding: 0 0.125rem;
    transform: rotate(20deg) translateY(2px);
    display: inline-block;
  }
`;

const TitleSlash = () => (
  <SlashWrap>
    <span>/</span>
    <span>/</span>
  </SlashWrap>
);

export default TitleSlash;

import * as React from "react";
import { Excerpt } from "./BlogExcerpt";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Header from "../../common/typography/SubHeading";
import { colors } from "../../../vars/palette";

const RelatedWrap = styled.div`
  .topics {
    text-align: left !important;
  }
  .date {
    font-size: 0.9rem !important;
    font-weight: normal !important;
    color: ${colors.hover("text", 0.2, "flip")} !important;
  }
`;

const RelatedBlogs = ({ related }) => {
  return related.length ? (
    <RelatedWrap className="related-blogs">
      <div style={{ textAlign: "center" }}>
        <Header text="Related Blogs" />
      </div>
      <Container>
        <Row>
          {related.map((rel, i) => (
            <Col key={"rel" + i} sm={12} md={6} lg={3}>
              <Excerpt
                blog={rel}
                toDataLayer={{ excerpt_type: "related_blog" }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </RelatedWrap>
  ) : null;
  // return tags.length && tags.map((tag,i)=>{
  //     return <div key={"tag"+i}>{tag}</div>
  // })
};

export default RelatedBlogs;
